.sidebar {
    height: 100vh;
    width: 150px;
    position: fixed;
    left: 0;
    top: 0;
    background-color: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
}

@media (max-width: 768px) {
    .sidebar {
        width: 0;
        overflow: hidden;
    }
}

.sidebar a {
    color: rgba(255, 255, 255, .8);
    text-decoration: none;
    font-size: 1.2rem;
    padding: 10px;
    transition: color 0.3s ease;
}

.sidebar a.active {
    color: #4CAF50; /* Active link color */
}

.sidebar a.inactive:hover {
    color: rgba(255, 255, 255, .92)
}
