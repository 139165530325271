@media (min-width: 769px) {
    .main-content {
        margin-top: 0;
        margin-left: 150px;
    }
}

@media (max-width: 768px) {
    .main-content {
        margin-top: 44px;
        margin-left: 0;
    }
}